
.institute {
    position: relative;

    .first {
        position: relative;
        height: 403px;
        background: #B2D1E0;

        .right {
            position: absolute;
            right: 0;
            display: flex;
            align-items: flex-end;
            padding-bottom: 30px;
            height: 403px;
            width: 50%;
            background: #EFEFEF;

            &>div {
                margin-left: 26%;
                width: 412px;
            }
        }

        dl {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 292px;
            height: 256px;

            &::before {
                position: absolute;
                left: -150px;
                content: '';
                display: inline-block;
                width: 150px;
                height: 191px;
                background: url("../../static/institute1.png") center center no-repeat;
            }

            dt {
                height: 191px;
                background: url('../../static/institute.png') center center no-repeat;
            }

            dd {
                height: 65px;
                line-height: 65px;
                background: #B2D1E0;

                h3 {
                    text-align: center;
                }
            }
        }
    }

    .two {
        padding: 80px 18%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        background: #fff;

        .title {
            margin: 10px 0 30px 0;
        }

        p, h3, div {
            display: inline-block;
            width: 604px;
        }
    }

}
