

.product {
    position: relative;

    .first {
        position: relative;
        height: 207px;
        background: #B2D1E0;

        .img {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 207px;
            background: url("../../static/product/product1.png") center bottom no-repeat;
        }

        .center {
            position: absolute;
            left: 0;
            top: 300px;
            bottom: 0;
            right: 0;
            z-index: 4;
            margin: auto;
            width: 410px;
            height: 207px;
            line-height: 207px;
            border: 2px solid #B2D1E0;
            background: #fff;

            h2 {
                text-align: center;
            }
        }

        .right {
            position: absolute;
            right: 14%;
            z-index: 4;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 347px;
            height: 332px;
            border: 20px solid #B2D1E0;
            background: #fff;

            p {
                margin: 0;
                padding: 0;
                text-align: center;

                &:first-child {
                    margin-top: 30px;
                }
            }

            .img {
                margin: auto;
                display: inline-block;
                width: 222px;
                height: 177px;
                background: url("../../static/product/product2.png") center center no-repeat;
            }

        }
    }

    .two {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 0 20% ;
        height: 360px;

        ul {
            height: 145px;
            width: 604px;
            list-style: none;
            font-size: 12px;
        }
    }

    .three,
    .four,
    .five,
    .six,
    .seven,
    .eight,
    .nine,
    .ten,
    .eleven,
    .twelve{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        padding: 0 20%;
        margin-right: 20px;

        ul {
            padding: 0;
            padding-left: 15px;
            font-size: 14px;
        }

        h3 {
            margin:40px 0;
            width: 640px;
            font-size: 24px;
        }

        h4, h3, ul, p {
            width: 640px;
            font-family: "Avenir Next";
        }

        dl {

            dd {
                width: 640px;
                font-family: "Avenir Next";
            }
        }
    }

    .six {
        p {
            position: relative;

            &:first-child::before {
                position: absolute;
                left: -221px;
                top: 0;
                display: inline-block;
                content: '';
                width: 180px;
                height: 191px;
                background: url("../../static/product/product3.png") center center no-repeat;
            }

            &:nth-child(3)::before {
                position: absolute;
                left: -221px;
                top: -40px;
                display: inline-block;
                content: '';
                width: 180px;
                height: 118px;
                background: url("../../static/product/product4.png") center center no-repeat;
            }
        }
    }

    .seven {

        p {
            position: relative;

            &:first-child::before {
                position: absolute;
                left: -221px;
                top: 0;
                display: inline-block;
                content: '';
                width: 180px;
                height: 263px;
                background: url("../../static/product/product5.png") center center no-repeat;
            }
        }
    }

    .eight {
        margin-bottom: 30px;
    }

    .nine {

        p {
            position: relative;

            &:first-child::before {
                position: absolute;
                left: -221px;
                top: 0;
                display: inline-block;
                content: '';
                width: 180px;
                height: 125px;
                background: url("../../static/product/B01.png") center center no-repeat;
            }

            &:nth-child(2)::before {
                position: absolute;
                left: -221px;
                top: -16px;
                display: inline-block;                
                content: 'B-series products';
                font-weight: bold;
                font-size: 16px;
                width: 180px;
                height: 118px;
            }
        }
    }

    .ten {
        margin-bottom: 30px;
    }

    .eleven {

        p {
            position: relative;

            &:first-child::before {
                position: absolute;
                left: -221px;
                top: 0;
                display: inline-block;
                content: '';
                width: 180px;
                height: 125px;
                background: url("../../static/product/H01.png") center center no-repeat;
            }

            &:nth-child(2)::before {
                position: absolute;
                left: -221px;
                top: 6px;
                display: inline-block;                
                content: 'H-series products';
                font-weight: bold;
                font-size: 16px;
                width: 180px;
                height: 118px;
            }
        }
    }

    .twelve {
        margin-bottom: 30px;
    }

    .thirteen {
        padding: 3% 9% 0 9%;
        display: flex;
        justify-content: space-evenly;
        height: 500px;
        background-color: #efefef;
        position: relative;

        h3 {
            margin:10px 0;
            width: 616px;
            font-size: 24px;
        }

        .c-title{
          padding-bottom: 30px;
        }
        
        .content{
            width: 984px;
            height: 260px;
            position: relative;

            .img1 {
              position: absolute;
              top: 0;
              z-index: 3;
              display: inline-block;
              width: 180px;
              height: 254px;
              background: url('../../static/product/c1.png') center center no-repeat;
            }
  
            .img2 {
              position: absolute;
              top: 0;
              left: 24%;
              z-index: 3;
              display: inline-block;
              width: 268px;
              height: 254px;
              background: url('../../static/product/c4.png') center center no-repeat;
            }
  
            .img3 {
              position: absolute;
              top: 0;
              right: 24%;
              z-index: 3;
              display: inline-block;
              width: 183px;
              height: 254px;
              background: url('../../static/product/c3.png') center center no-repeat;
            }
  
            .img4 {
              position: absolute;
              top: 0;
              right:0;
              z-index: 3;
              display: inline-block;
              width: 177px;
              height: 254px;
              background: url('../../static/product/c2.png') center center no-repeat;
            }
        }
        
    
      }

}





