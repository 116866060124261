@import '~antd/dist/antd.css';

.wrap {
  position: relative;
  overflow: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 9.6%;
    height: 120px;
    width: 100%;
    line-height: 120px;

    .logo {
      cursor: pointer;
      display: inline-block;
      width: 202px;
      height: 22px;
      background: url("./static/home/logo.png") center center no-repeat;
    }
  }

  .footer {
    padding: 0 20%;
    display: flex;
    justify-content: space-evenly;
    height: 114px;
    line-height: 114px;
    color: #fff;
    background-color: #B2D1E0;
  }
}

.ant-tabs-tab {
  padding: 0;

  >div {
    height: 46px;
    line-height: 46px;

    a {
      display: inline-block;
      color: #39393B;
    }
  }
}

.ant-tabs-nav::before  {
  border-bottom: none !important;
}

.ant-tabs-ink-bar {
  height: 4px;
}

@media screen and (min-width: 1440px){
  .wrap {
    width: 1440px;
    margin: auto;
  }
}

p, li, dt, dd {
  font-family: "Avenir Next";
  font-size: 14px;
}