
.studies {
    position: relative;

    .first {
        position: relative;
        height: 390px;
        margin-bottom: 80px;
        background: url("../../static/studies/studies1.png") center center no-repeat;

        .text {
            position: relative;
            padding: 0 14% 0 14%;
            bottom: -200px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-evenly;
            width: 100%;
            height: 200px;
            background: rgba(239, 239, 239, 0.9);
    
            p {
                display: inline-block;
                width: 600px;
                font-size: 16px;
            }
        }
    }
    
    .two,
    .three,
    .four,
    .five,
    .six,
    .seven,
    .eight,
    .nine,
    .ten,
    .eleven,
    .twelve,
    .thirteen,
    .fourteen{

        p {
            font-family: "Avenir Next";
            font-size: 14px;
          }
        
        h3 {
          font-size: 24px;
          text-align: left;
        }
        
        h4 {
          font-size: 20px;
          text-align: left;
        }
    
        h5 {
            font-size: 16px;
            text-align: left;
          }
    
    }

    .two{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 480px;

        .left{
            position: relative;
      
            .two-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
            
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .three{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 490px;

        .left{
            position: relative;
      
            .three-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .img {
              position: absolute;
              top: 210px;
              left: 0;
              z-index: 3;
              display: inline-block;
              width: 180px;
              height: 242px;
              background: url('../../static/studies/studies2.png') center center no-repeat;
            }
            
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .four{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 530px;

        .left{
            position: relative;
      
            .four-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }
    
    .five{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 410px;

        .left{
            position: relative;
      
            .five-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .six{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 570px;

        .left{
            position: relative;
      
            .six-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .img {
              position: absolute;
              top: 290px;
              left: 0;
              z-index: 3;
              display: inline-block;
              width: 180px;
              height: 231px;
              background: url('../../static/studies/studies3.png') center center no-repeat;
            }
            
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .seven{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 260px;

        .left{
            position: relative;
      
            .seven-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .eight{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 470px;

        .left{
            position: relative;
      
            .eight-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }

        h6{
            font-size: 14px;
            text-align: left;
        }
    }

    .nine{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 680px;

        .left{
            position: relative;
      
            .nine-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
            
            .point{
                font-weight: bold;
            }
          }
    }

    .ten{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 320px;

        .left{
            position: relative;
      
            .ten-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .eleven{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 790px;

        .left{
            position: relative;
      
            .nine-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }

            .point{
                font-weight: bold;
            }
    
            .end{
                font-size: 15px;
                font-weight: normal;
                margin-left: 10px;
            }
          }
    }

    .twelve{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 540px;

        .left{
            position: relative;
      
            .twelve-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .thirteen{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 280px;

        .left{
            position: relative;
      
            .thirteen-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }
          }
    }

    .fourteen{
        padding: 0 14%;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        height: 750px;

        .left{
            position: relative;
      
            .fourtee-title{
                padding-bottom: 10px;
                cursor: pointer;
            }

            .link-top{
                width: 244px;
                height: 3px;
                border-top: solid #ACC0D8 2px;
            }
      
            .content {
              display: flex;
              align-items: flex-end;
              justify-content: left;
              position: absolute;
              margin-top: 10px;
              width: 240px;
              display: inline-block;
            }
          }
      
          &> div {
            width: 600px;
      
            &:first-child {
              width: 500px;
            }
          }
      
          .right{
            position: relative;
            margin-left: 20px;
      
            .text {
              width: 600px;
              
              p{
                margin-top: 10px;
              }
            }

            .point{
                font-weight: bold;
            }
          }    
    }
}

